import React from "react"

// import bannerImage from "../../../images/VE-banner.jpg"

// import Image from "atomic-core/components/patterns/01-atoms/images/Image"
// import Heading from "atomic-core/components/patterns/01-atoms/texts/heading/Heading"
// import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text"
// import Media from "atomic-core/components/patterns/02-molecules/media/Media"
// import Description from "atomic-core/components/patterns/02-molecules/description/Description"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const SpeakerDescripton = props => {
  const { styles = {}, onClose = null, name = "", description = "" } = props

  return (
    <div className="speaker-description" style={styles}>
      <div className="background-color-monochrome-1 color-secondary-2 mb-20">
        <div className="d-xs-flex width-full xs-between pt-20 pr-20 pl-20">
          <h5 className="mb-0 font-secondary-bold text-size-medium color-secondary-2">
            About {name}
          </h5>
          <a
            href="#"
            className="icon-size-40 color-secondary-2"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
        <div className="description color-primary-1">
          <div className="text mb-10 font-secondary-regular text-size-medium">
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpeakerDescripton
